import { Avatar } from "@mui/material";
import React from "react";

export default ({
  product,
  backupSrc = "",
  sx = {},
}: {
  product: any;
  backupSrc?: string;
  sx: any;
}) => {
  const getProductInitials = () => {
    return product.name
      .split(" ")
      .map((name) => name[0].toUpperCase())
      .splice(0, 3)
      .join("");
  };
  return (
    <Avatar
      sx={{ ...sx, backgroundColor: "#fff" }}
      variant="rounded"
      alt={product.name}
      src={product.logo_url}
      srcSet={`${product.logo_url}, ${backupSrc}`}
      slotProps={{
        img: {
          style: {
            objectFit: "contain",
          },
        },
      }}
    >
      {getProductInitials()}
    </Avatar>
  );
};
