import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

export default () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Typography
            variant="h6"
            sx={{
              background: theme.palette.secondary.light,
              borderRadius: "50%",
              width: 27,
              height: 27,
              textAlign: "center",
              mr: 1.5,
            }}
          >
            1
          </Typography>
          <Typography variant="h6">Choose how you want to get paid</Typography>
        </Box>
        <Typography variant="body1">
          Select a payment method to get your Cash Back.
        </Typography>
        <Button variant="outlined" sx={{ mt: 2 }}>
          Choose Payment Method
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Paper
            style={{
              padding: "16px",
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <Typography variant="h6">Confirmed Cash Back</Typography>
            <Typography variant="h3">$0.00</Typography>
            <Typography variant="body2">Pending</Typography>
            <Typography variant="body1">$0.00</Typography>
            <Button variant="outlined" sx={{ mt: 2 }}>
              See activity
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Typography variant="h6">
              <CalendarTodayIcon /> Next Payment: 02/15/2025
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
