import {
  AccountTree,
  Add,
  ChevronLeft,
  ChevronRight,
  Feed,
  Inventory,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LockIcon from "@mui/icons-material/Lock";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const customerScreens = [
  { screen: "dashboard", icon: <DashboardIcon />, text: "Dashboard" },
  { screen: "account", icon: <AccountCircleIcon />, text: "Account" },
  { screen: "canvas", icon: <AccountTree />, text: "Canvas" },
  { screen: "messages", icon: <MessageIcon />, text: "Messages" },
  { screen: "settings", icon: <SettingsIcon />, text: "Settings" },
  { screen: "privacy", icon: <LockIcon />, text: "Privacy" },
  { screen: "credit-cards", icon: <CreditCardIcon />, text: "Cards" },
  { screen: "subscriptions", icon: <EmailIcon />, text: "Emails" },
  { screen: "favorites", icon: <FavoriteIcon />, text: "Favorites" },
];

const companyScreens = [
  { screen: "company-dashboard", icon: <DashboardIcon />, text: "Dashboard" },
  { screen: "products", icon: <Inventory />, text: "Products" },
  { screen: "profile", icon: <Feed />, text: "Profile" },
  { screen: "company-messages", icon: <MessageIcon />, text: "Messages" },
  { screen: "settings", icon: <SettingsIcon />, text: "Settings" },
  { screen: "privacy", icon: <LockIcon />, text: "Privacy" },
  { screen: "credit-cards", icon: <CreditCardIcon />, text: "Cards" },
  { screen: "subscriptions", icon: <EmailIcon />, text: "Emails" },
];

export default ({
  screen,
  setPageView,
  name,
  userType,
  activeCompanyId,
  activeCompanyChange,
  organizationCompanies,
}: {
  screen: string;
  setPageView: (screen: string) => void;
  name: string;
  userType: string;
  activeCompanyId: string;
  activeCompanyChange: (e: any) => void;
  organizationCompanies: Array<any>;
}) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [screens, setScreens] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(() => {
    if (userType === "company") {
      setScreens(companyScreens);
    } else {
      setScreens(customerScreens);
    }
  }, [userType]);

  return (
    <List
      sx={{
        width: isSidebarCollapsed ? 64 : 260,
        transition: "width 0.3s ease",
        overflowX: "hidden",
      }}
    >
      {/* Collapse Button */}
      <ListItem
        sx={{
          justifyContent: isSidebarCollapsed ? "center" : "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {!isSidebarCollapsed && (
          <Box>
            <Typography variant="h6">Hi David</Typography>
            <Typography variant="body2">$0.00 Lifetime Cash Back</Typography>
            <Typography variant="body2">Member Since 11/10/2024</Typography>
          </Box>
        )}
        <IconButton onClick={toggleSidebar} sx={{ mt: 2 }}>
          {isSidebarCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </ListItem>

      {/* Collapsed Referral Button */}
      {!isSidebarCollapsed && (
        <ListItem>
          <Button
            sx={{ color: "black", borderColor: "gray" }}
            variant="outlined"
          >
            Refer & Earn 10% off
          </Button>
        </ListItem>
      )}

      {!isSidebarCollapsed && userType === "company" && (
        <ListItem>
          <Select
            fullWidth
            value={activeCompanyId}
            onChange={activeCompanyChange}
          >
            {organizationCompanies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
            <MenuItem color="primary" value="create-company">
              <Add sx={{ mr: 1 }} />
              <ListItemText primary="Create Company" />
            </MenuItem>
          </Select>
        </ListItem>
      )}

      {screens.map((item) => (
        <ListItemButton
          key={item.screen}
          selected={screen === item.screen}
          disabled={screen === item.screen}
          onClick={() => setPageView(item.screen)}
          sx={{
            justifyContent: isSidebarCollapsed ? "center" : "initial",
            px: isSidebarCollapsed ? 2 : 3,
          }}
        >
          <ListItemIcon sx={{ minWidth: isSidebarCollapsed ? 0 : 56 }}>
            {item.icon}
          </ListItemIcon>
          {!isSidebarCollapsed && <ListItemText primary={item.text} />}
        </ListItemButton>
      ))}
    </List>
  );
};
