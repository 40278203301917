// src/components/SignupFlow.tsx
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackendApi from "./BackendApi";
import LoadingButton from "./components/LoadingButton";
import LoadingScreen from "./components/LoadingScreen";
import { companySizes, industries } from "./constants";

type UserType = "customer" | "company" | "consultant" | null;
type FormData = {
  name: string;
  website: string;
  company_size: string;
  industry: string;
  location: string;
};

export default ({ supabase }: { supabase: SupabaseClient }) => {
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [session, setSession] = useState<Session | null>(null);
  const [userType, setUserType] = useState<UserType>(null);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    website: "",
    company_size: "",
    industry: "",
    location: "",
  });
  const navigate = useNavigate();
  const backendApi = new BackendApi(supabase, session);

  const handleRoleSelect = (role: UserType) => {
    setUserType(role);
    if (
      organizations.length > 0 &&
      session.user.user_metadata.active_organization_id
    ) {
      supabase.auth.updateUser({
        data: {
          active_user_type: role,
        },
      });
      navigate("/dashboard");
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    // Handle form submission logic here
    console.log("Form submitted:", { userType, ...formData });
    const organization = await backendApi.saveOrganization(formData);
    supabase.auth.updateUser({
      data: {
        active_user_type: userType,
        active_organization_id: organization.id,
      },
    });
    navigate("/dashboard");
    setLoading(false);
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      const userType = session.user.user_metadata.active_user_type;
      if (userType) {
        navigate("/dashboard");
      }
      backendApi.getOrganizations(session).then((res) => {
        setOrganizations(res);
      });
    });
  }, [supabase]);

  function handleOrganizationSelect(organization: any): void {
    supabase.auth.updateUser({
      data: {
        active_user_type: userType,
        active_organization_id: organization.organization_id,
      },
    });
    navigate("/dashboard");
  }

  if (!session) {
    return <LoadingScreen open={true} />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: 800, margin: "auto" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Join Congruent
        </Typography>

        {!userType ? (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                Select Your Role
              </Typography>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                {["customer", "company", "consultant"].map((role) => (
                  <Grid item xs={12} md={12} key={role}>
                    <Card
                      sx={{
                        p: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => handleRoleSelect(role as UserType)}
                    >
                      <Typography variant="h6" align="center">
                        {role.charAt(0).toUpperCase() + role.slice(1)}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        ) : (
          (organizations.length > 0 && (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom align="center">
                  Select Organization
                </Typography>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  {organizations.map((organization) => (
                    <Grid item xs={12} md={12} key={organization.id}>
                      <Card
                        sx={{
                          p: 3,
                          cursor: "pointer",
                        }}
                        onClick={() => handleOrganizationSelect(organization)}
                      >
                        <Typography variant="h6" align="center">
                          {organization.organizations.name}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )) || (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom align="center">
                  {`${
                    userType.charAt(0).toUpperCase() + userType.slice(1)
                  } Registration`}
                </Typography>

                <Grid container spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Organization Name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Website"
                      value={formData.website}
                      onChange={(e) =>
                        setFormData({ ...formData, website: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Company Size</InputLabel>
                      <Select
                        value={formData.company_size}
                        label="Company Size"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            company_size: e.target.value,
                          })
                        }
                      >
                        {companySizes.map((size) => (
                          <MenuItem key={size} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Industry</InputLabel>
                      <Select
                        value={formData.industry}
                        label="Industry"
                        onChange={(e) =>
                          setFormData({ ...formData, industry: e.target.value })
                        }
                      >
                        {industries.map((industry) => (
                          <MenuItem key={industry} value={industry}>
                            {industry}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Location"
                      value={formData.location}
                      onChange={(e) =>
                        setFormData({ ...formData, location: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button variant="outlined" onClick={() => setUserType(null)}>
                    Back
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={handleFormSubmit}
                  >
                    Sign Up
                  </LoadingButton>
                </Box>
              </CardContent>
            </Card>
          )
        )}

        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          Already have an account? <Button href="/">Log in</Button>
        </Typography>
      </Box>
    </Box>
  );
};
