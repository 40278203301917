import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import moment from "moment";
import React from "react";

// Styled components
const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isOwn",
})(({ theme, isOwn }: { theme: Theme; isOwn: boolean }) => ({
  maxWidth: "70%",
  minWidth: "150px",
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  backgroundColor: isOwn
    ? theme.palette.secondary.light
    : theme.palette.grey[100],
  color: isOwn ? theme.palette.text.secondary : theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const MessageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOwn",
})(({ isOwn }: { isOwn: boolean }) => ({
  display: "flex",
  justifyContent: isOwn ? "flex-end" : "flex-start",
  width: "100%",
  gap: "8px",
}));

export default ({ messages }: { messages: Array<any> }) => {
  const MessageAvatar = React.useMemo(
    () =>
      ({ message }: { message: any }) => {
        const member = message.member;
        if (member.name) {
          return (
            <Avatar
              src={member.logo_url}
              alt={member.name}
              sx={{ width: 32, height: 32 }}
            />
          );
        } else {
          return <Avatar src={""} alt={""} sx={{ width: 32, height: 32 }} />;
        }
      },
    [messages]
  );

  const getMemberName = (message: any) => {
    const member = message.member;
    if (member.name) {
      return member.name;
    } else {
      if (
        member &&
        member.raw_user_meta_data &&
        member.raw_user_meta_data.name
      ) {
        return member.raw_user_meta_data.name;
      }
      return "";
    }
  };

  const isOwnMessage = (message: any): boolean => {
    const member = message.member;
    if (member.name) {
      return true;
    } else if (member && member.raw_user_meta_data) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MessageContainer>
      {!messages ||
        (messages.length === 0 && (
          <Box sx={{ color: "gray" }}>No Messages</Box>
        ))}
      {messages.map((message) => (
        <MessageWrapper key={message.id} isOwn={isOwnMessage(message)}>
          <MessageAvatar message={message} />
          <Box sx={{ maxWidth: "70%" }}>
            {!message.isOwn && (
              <Typography
                variant="caption"
                sx={{ ml: 1, mb: 0.5, display: "block" }}
                color="text.secondary"
              >
                {getMemberName(message)}
              </Typography>
            )}
            {/* @ts-ignore */}
            <MessageBubble isOwn={isOwnMessage(message)}>
              <Typography variant="body1">{message.message}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 0.5,
                  mt: 0.5,
                }}
              >
                <Typography
                  variant="caption"
                  color={
                    message.isOwn ? "primary.contrastText" : "text.secondary"
                  }
                  sx={{ opacity: 0.8 }}
                >
                  {moment(message.created_date).format("HH:mm")}
                </Typography>
                {message.isOwn && (
                  <DoneAllIcon
                    sx={{
                      width: 16,
                      height: 16,
                      color: "primary.contrastText",
                      opacity: 0.8,
                    }}
                  />
                )}
              </Box>
            </MessageBubble>
          </Box>
        </MessageWrapper>
      ))}
    </MessageContainer>
  );
};
