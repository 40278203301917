import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  RealtimeChannel,
  Session,
  SupabaseClient,
} from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import ChatMessages from "./ChatMessages";

// Styled components
const ChatContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
}));

const ConversationsList = styled(Paper)(({ theme }) => ({
  height: "100%",
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ChatArea = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const MessageInput = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export default ({
  backendApi,
  session,
  supabase,
}: {
  backendApi: BackendApi;
  session: Session;
  supabase: SupabaseClient;
}) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState<any>({});
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<RealtimeChannel>(null);

  const getConversationMessages = async () => {
    if (!selectedConversation.id) return;
    setLoading(true);
    const messages = await backendApi.getConversationMessages(
      selectedConversation.id
    );
    setMessages(messages);
    setLoading(false);
  };

  const getConversations = async () => {
    setLoading(true);
    const conversations = await backendApi.getConversations(false);
    setConversations(conversations);
    if (conversations && conversations.length > 0) {
      setSelectedConversation(conversations[0]);
      const chan = supabase
        .channel(conversations[0].id)
        .on("broadcast", { event: "change" }, ({ payload }) => {
          getConversationMessages();
        })
        .subscribe((status) => {
          console.log("Subscribed to conversation changes", status);
        });
      setChannel(chan);
    }
    setLoading(false);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!Object.keys(selectedConversation) || !message) return;
    var memberId = session.user.id;
    await backendApi.sendConversationMessage(
      selectedConversation.id,
      memberId,
      message
    );
    setMessage("");
    getConversationMessages();
    channel.send({
      type: "broadcast",
      event: "change",
      payload: {
        type: "new_message",
      },
    });
  };

  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    getConversationMessages();
  }, [selectedConversation]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ display: loading ? "" : "none" }}>
        <LinearProgress />
      </Box>
      <ChatContainer>
        <Grid container sx={{ height: "100%" }}>
          {/* Conversations List */}
          <Grid item xs={3}>
            <ConversationsList elevation={0}>
              <Typography variant="h6" sx={{ p: 2 }}>
                Conversations
              </Typography>
              <Divider />
              <List>
                {conversations.map((conversation) => (
                  <ListItemButton
                    selected={conversation.id === selectedConversation.id}
                    disabled={conversation.id === selectedConversation.id}
                    onClick={() => setSelectedConversation(conversation)}
                    key={conversation.id}
                  >
                    <ListItemAvatar>
                      <Avatar src={conversation.image_url} />
                    </ListItemAvatar>
                    <ListItemText primary={conversation.name} />
                  </ListItemButton>
                ))}
              </List>
            </ConversationsList>
          </Grid>

          {/* Chat Area */}
          <Grid item xs={9}>
            <ChatArea elevation={0}>
              {/* Chat Header */}
              <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                <Typography variant="h6">
                  {selectedConversation?.name}
                </Typography>
              </Box>

              {/* Messages Area */}
              <Box sx={{ flexGrow: 1, p: 2, overflowY: "auto" }}>
                <ChatMessages messages={messages} />
              </Box>

              {/* Message Input */}
              <MessageInput>
                <form onSubmit={sendMessage}>
                  <Grid container spacing={2}>
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        placeholder="Type a message..."
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", justifyItems: "center" }}
                    >
                      <IconButton color="default" type="submit">
                        <SendIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
              </MessageInput>
            </ChatArea>
          </Grid>
        </Grid>
      </ChatContainer>
    </Box>
  );
};
