import { Fullscreen } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BackendApi from "./BackendApi";
import DashboardSidebar from "./DashboardSidebar";
import Home from "./Home";
import Logo from "./Logo";
import AccountPage from "./account/AccountPage";
import Canvas from "./canvas/Canvas";
import CanvasModal from "./canvas/CanvasModal";
import { identify } from "./capture";
import Company from "./company/Company";
import CompanyProductMetrics from "./company/CompanyProductMetrics";
import CompanyProducts from "./company/CompanyProducts";
import CompanyProfile from "./company/CompanyProfile";
import Consultant from "./consultant/Consultant";
import NewChatPage from "./messaging/ChatPage";
import CompanyMessages from "./messaging/CompanyMessages";
import Organization from "./organization/Organization";
import OrganizationSettings from "./organization/OrganizationSettings";
import UserTopMenu from "./user/UserTopMenu";

const ConsumerDashboard = ({ supabase }: { supabase: SupabaseClient }) => {
  const theme = useTheme();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [screen, setScreen] = useState(params.topPage || "account");
  const [session, setSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [pendingOrganizations, setPendingOrganizations] = useState([]);
  const [orgCreateOpen, setOrgCreateOpen] = useState(false);
  const [activeOrgId, setActiveOrgId] = useState("");
  const [activeOrgUserId, setActiveOrgUserId] = useState("");
  const [activeCompany, setActiveCompany] = useState<any>(null);
  const [activeCompanyId, setActiveCompanyId] = useState<any>(null);
  const [selectOrgOpen, setSelectOrgOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElNotications, setAnchorElNotifications] =
    useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState([]);
  const [openConfirmInvite, setOpenConfirmInvite] = useState(false);
  const [acceptInviteOrg, setAcceptInviteOrg] = useState("");
  const [categories, setCategories] = useState([]);
  const [user, setUser] = useState<any>(null);
  const [fullScreenCanvas, setFullScreenCanvas] = useState(false);
  const [userType, setUserType] = useState("");
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<any | null>(null);
  const [organizationCompanies, setOrganizationCompanies] = useState([]);
  const [createCompanyOpen, setCreateCompanyOpen] = useState(false);
  const [name, setName] = useState();
  const backendApi = new BackendApi(supabase, session);
  const mode = "light";

  const getUser = async () => {
    const res = await backendApi.getMe();
    setUser(res);
  };

  useEffect(() => {
    if (session && session.access_token) {
      getUser();
    }
  }, [session]);

  useEffect(() => {
    if (params.topPage) {
      setScreen(params.topPage);
    }
  }, [params]);

  const getCategories = async (session: Session) => {
    const categories = await backendApi.getCategoriesTree(session);
    setCategories(categories);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const logout = () => {
    supabase.auth.signOut();
    navigate("/");
  };

  const setPageView = (page: string) => {
    setScreen(page);
    setOpenDrawer(false);
    navigate("/dashboard/" + page);
  };

  const getCompanies = async (activeCompanyId: string) => {
    const res = await backendApi.getCompanies(session.user.id, activeOrgId);
    const companies = res;
    setOrganizationCompanies(companies);
    if (activeCompanyId && companies.length !== 0) {
      const activeCompany = companies.find((x) => x.id === activeCompanyId);
      setActiveCompany(activeCompany);
    } else if (companies.length !== 0) {
      setActiveCompanyId(companies[0].id);
      setActiveCompany(companies[0]);
    } else {
      setCreateCompanyOpen(true);
    }
  };

  const activeCompanyChange = (e: any) => {
    if (e.target.value === "create-company") {
      setCreateCompanyOpen(true);
      return;
    }
    supabase.auth.updateUser({
      data: {
        active_company_id: e.target.value,
      },
    });
    setActiveCompanyId(e.target.value);
    setActiveCompany(
      organizationCompanies.find((x) => x.id === e.target.value)
    );
  };

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        identify(session);
        setSession(session);
        getPendingOrganizations(session);
        getOrganizations(session);
        getNotifications(session);
        getCategories(session);
        setUserType(session.user.user_metadata.active_user_type);
        setName(session.user.user_metadata.name);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      identify(session);
      setSession(session);
      getPendingOrganizations(session);
      getOrganizations(session);
      getNotifications(session);
      getCategories(session);
      setUserType(session.user.user_metadata.active_user_type);
      setName(session.user.user_metadata.name);
    });
    console.log(subscription);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (session && session.user && session.user.user_metadata) {
      getCompanies(session.user.user_metadata.active_company_id);
      setActiveCompanyId(session.user.user_metadata.active_company_id);
    }
  }, [session, activeOrgId]);

  useEffect(() => {
    if (userType === "company") {
      setPageView("company-dashboard");
    } else {
      setPageView("account");
    }
  }, [userType]);

  const getNotifications = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    const notifications = await backendApi.getNotications(
      currActiveOrgId,
      sess.user.id
    );
    setNotifications(notifications);
  };

  const getOrganizations = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    backendApi.getOrganizations(sess).then((organizations) => {
      setOrganizations(organizations);
      if (currActiveOrgId && organizations.length !== 0) {
        setActiveOrgId(currActiveOrgId);
        const selectedOrg = organizations.find(
          (x) => x.organization_id === currActiveOrgId
        );
        setActiveOrgUserId(selectedOrg.id);
      } else if (organizations.length !== 0) {
        setActiveOrgId(organizations[0].organization_id);
        setActiveOrgUserId(organizations[0].id);
      } else {
        setOrgCreateOpen(true);
      }
    });
  };

  const getPendingOrganizations = async (sess: any) => {
    const pending = await backendApi.getPendingOrganizations(sess.user.email);
    setPendingOrganizations(pending);
    pending.forEach((org: any) => {
      if (!org.accepted) {
        setOpenConfirmInvite(true);
        setAcceptInviteOrg(org.organizations.name);
      }
    });
  };

  const getActiveOrgName = () => {
    if (
      activeOrgId &&
      organizations.length !== 0 &&
      organizations.find((x) => x.organization_id === activeOrgId)
    ) {
      return organizations.find((x) => x.organization_id === activeOrgId)
        .organizations.name;
    }
    return "None";
  };

  const setActiveOrganizationId = (orgId) => {
    setActiveOrgId(orgId);
    supabase.auth.updateUser({
      data: {
        active_organization_id: orgId,
      },
    });
    setSelectOrgOpen(false);
    setAnchorEl(null);
    getOrganizations(session, orgId);
  };

  const createNewOrganization = () => {
    setSelectOrgOpen(false);
    setAnchorEl(null);
    setOrgCreateOpen(true);
  };

  const confirmOrganization = async () => {
    await backendApi.joinOrganizations(
      pendingOrganizations
        .map((x) => {
          return {
            id: x.id,
            organization_id: x.organization_id,
            role: x.role,
            accepted: x.accepted,
            user_id: session.user.id,
            name: session.user.user_metadata.name,
            photo_url: session.user.user_metadata.avatar_url,
          };
        })
        .filter((x) => !x.accepted)
    );
  };

  const getScreenName = () => {
    const mapping: any = {
      dashboard: "Dashboard",
      account: "Account",
      "credit-cards": "Credit Cards",
      settings: "Account Settings",
      "company-dashboard": "Dashboard",
      "company-messages": "Messages",
    };
    return (
      mapping[screen] ||
      screen.slice(0, 1).toUpperCase() + screen.slice(1, screen.length)
    );
  };

  return (
    <Box flex={1}>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "#ffffff", color: "#000000" }}
      >
        <Toolbar component={Container}>
          <Logo mode={"light"} />
          <Typography sx={{ ml: 2 }}>Categories</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton color="inherit">
            <Typography variant="body1">Help</Typography>
          </IconButton>
          <Box mx={2}>
            <Button
              variant="text"
              onClick={(e) => setUserMenuAnchorEl(e.target)}
            >
              David $0.00
            </Button>
          </Box>
        </Toolbar>
        <Divider />
        <Toolbar sx={{ justifyContent: "center" }}>
          <Box mx={2}>
            <Typography variant="body1">Extra Cash Back</Typography>
          </Box>
          <Box mx={2}>
            <Typography variant="body1">Hot Deals</Typography>
          </Box>
          <Box mx={2}>
            <Typography variant="body1">
              Refer & Earn up to 10% off your next software purchase
            </Typography>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Container sx={{ height: "calc(100vh - 300px)" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
          <DashboardSidebar
            screen={screen}
            setPageView={setPageView}
            name={name}
            userType={userType}
            activeCompanyId={activeCompanyId}
            activeCompanyChange={activeCompanyChange}
            organizationCompanies={organizationCompanies}
          />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5">{getScreenName()}</Typography>
            </Box>
            <Box
              sx={{
                mt: 4,
                mb: 4,
                height: "100%",
              }}
            >
              {screen === "canvas" && !fullScreenCanvas && (
                <Box>
                  <Button
                    sx={{ color: "black", borderColor: "gray", mb: 2 }}
                    variant={"outlined"}
                    onClick={() => setFullScreenCanvas(true)}
                    startIcon={<Fullscreen />}
                  >
                    Full Screen
                  </Button>
                  <Canvas
                    mode={"light"}
                    supabase={supabase}
                    session={session}
                    activeOrgId={activeOrgId}
                    activeOrgUserId={activeOrgUserId}
                    backendApi={backendApi}
                    categories={categories}
                    canvasHeight="calc(100vh - 407px)"
                  />
                </Box>
              )}
              {screen === "company-dashboard" && (
                <CompanyProductMetrics
                  session={session}
                  backendApi={backendApi}
                  activeOrgId={activeOrgId}
                  activeCompanyId={activeCompanyId}
                  activeCompany={activeCompany}
                />
              )}
              {screen === "dashboard" && <Home />}
              {screen === "products" && (
                <CompanyProducts
                  session={session}
                  backendApi={backendApi}
                  activeOrgId={activeOrgId}
                  activeCompanyId={activeCompanyId}
                />
              )}
              {screen === "profile" && (
                <CompanyProfile
                  session={session}
                  backendApi={backendApi}
                  activeOrgId={activeOrgId}
                  activeCompany={activeCompany}
                  formOnly={false}
                  onSave={() => {}}
                  fileId="company-file-select"
                />
              )}
              {screen === "company" && (
                <Company
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  activeOrgUserId={activeOrgUserId}
                  backendApi={backendApi}
                />
              )}
              {screen === "organization" && (
                <Organization
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  backendApi={backendApi}
                />
              )}
              {screen === "settings" && (
                <OrganizationSettings
                  backendApi={backendApi}
                  activeOrgId={activeOrgId}
                />
              )}
              {screen === "consultant" && (
                <Consultant
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  backendApi={backendApi}
                />
              )}
              {screen === "account" && <AccountPage />}
              {screen === "messages" && (
                <NewChatPage
                  backendApi={backendApi}
                  session={session}
                  supabase={supabase}
                />
              )}
              {screen === "company-messages" && (
                <CompanyMessages
                  session={session}
                  backendApi={backendApi}
                  supabase={supabase}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <CanvasModal
        open={fullScreenCanvas}
        onClose={() => setFullScreenCanvas(false)}
        mode={"light"}
        supabase={supabase}
        session={session}
        activeOrgId={activeOrgId}
        activeOrgUserId={activeOrgUserId}
        backendApi={backendApi}
        categories={categories}
        canvasHeight="calc(100vh - 407px)"
      />
      <UserTopMenu
        onClose={() => setUserMenuAnchorEl(null)}
        supabase={supabase}
        session={session}
        anchorEl={userMenuAnchorEl}
        getActiveOrgName={getActiveOrgName}
        organizations={organizations}
        activeOrgId={activeOrgId}
        setActiveOrganizationId={setActiveOrganizationId}
        userType={userType}
        setUserType={setUserType}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  );
};

export default ConsumerDashboard;
