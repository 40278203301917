import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import LoadingButton from "../components/LoadingButton";
import { companySizes, industries } from "../constants";

export default ({
  backendApi,
  activeOrgId,
}: {
  backendApi: BackendApi;
  activeOrgId: string;
}) => {
  const [organization, setOrganization] = useState({
    id: "",
    name: "",
    website: "",
    company_size: "",
    industry: "",
    location: "",
  });
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getOrganization = async () => {
    setLoading(true);
    const organization = await backendApi.getOrganization(activeOrgId);
    setOrganization(organization);
    setLoading(false);
  };

  useEffect(() => {
    // Fetch the initial organization data from API
    if (activeOrgId) {
      getOrganization();
    }
  }, [activeOrgId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    await backendApi.saveOrganization(organization);
    setButtonLoading(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Organization Name"
            name="name"
            value={organization.name}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Website"
            name="website"
            value={organization.website}
            onChange={handleChange}
          />
        </Box>
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>Company Size</InputLabel>
            <Select
              value={organization.company_size}
              label="Company Size"
              onChange={(e) =>
                setOrganization({
                  ...organization,
                  company_size: e.target.value,
                })
              }
            >
              {companySizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>Industry</InputLabel>
            <Select
              value={organization.industry}
              label="Industry"
              onChange={(e) =>
                setOrganization({ ...organization, industry: e.target.value })
              }
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Location"
            name="location"
            value={organization.location}
            onChange={handleChange}
          />
        </Box>
        <LoadingButton
          loading={buttonLoading}
          type="submit"
          variant="contained"
          color="primary"
        >
          Save Changes
        </LoadingButton>
      </form>
    </Box>
  );
};
