import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import CompanyLogo from "./CompanyLogo";

export default ({
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
  activeCompany,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
  activeCompany: any;
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProducts = async (page) => {
    if (!session || !session.user || !activeOrgId || !activeCompanyId) return;
    setLoading(true);
    const { items, total } = await backendApi.getProductMetrics(
      activeCompanyId,
      page,
      25
    );
    setProducts(items);
    setTotal(total);
    setLoading(false);
  };

  useEffect(() => {
    getProducts(page);
  }, [activeOrgId, activeCompanyId]);

  const getAddedToCanvas = (count: number) => {
    return count === 0 ? parseInt(`${Math.random() * 1000}`) : count;
  };

  return (
    <Box>
      <LinearProgress sx={{ display: loading ? "block" : "none", mt: 1 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Added To Canvas</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Impressions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.name}>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  <CompanyLogo
                    product={product}
                    backupSrc={activeCompany.logo_url}
                    sx={{}}
                  />
                </TableCell>
                <TableCell>
                  {product.categories.map((x) => x.name).join(",  ") ||
                    "General"}
                </TableCell>
                <TableCell>{product.addedToCanvas}</TableCell>
                <TableCell>{product.leads}</TableCell>
                <TableCell>{product.impressions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
