import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default ({ open }: { open: boolean }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 1398 }}>
      <CircularProgress style={{ zIndex: 1399 }} color="secondary" />
    </Backdrop>
  );
};
