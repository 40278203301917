import { Check, Logout, UnfoldMore, Wysiwyg } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default ({
  supabase,
  session,
  anchorEl,
  setAnchorEl,
  onClose,
  getActiveOrgName,
  organizations,
  activeOrgId,
  setActiveOrganizationId,
  userType,
  setUserType,
}: {
  supabase: SupabaseClient;
  session: Session;
  anchorEl: any;
  setAnchorEl: any;
  onClose: () => void;
  getActiveOrgName: Function;
  organizations: Array<any>;
  activeOrgId;
  setActiveOrganizationId: Function;
  userType: string | undefined | null;
  setUserType: (type: string) => void;
}) => {
  const navigate = useNavigate();
  const [orgAnchorEl, setOrgAnchorEl] = useState(null);
  const logout = () => {
    supabase.auth.signOut();
    navigate("/");
  };

  const updateUserType = (newUserType) => {
    supabase.auth.updateUser({
      data: {
        active_user_type: newUserType,
      },
    });
    setUserType(newUserType);
    onClose();
  };

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      <List sx={{ minWidth: 240 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={(e: any) => setOrgAnchorEl(e.currentTarget)}>
            <ListItemText primary={getActiveOrgName()} />
            <UnfoldMore />
          </ListItemButton>
          <Menu
            open={Boolean(orgAnchorEl)}
            anchorEl={orgAnchorEl}
            onClose={() => setOrgAnchorEl(null)}
          >
            {organizations.map((org) => (
              <MenuItem
                key={org.organization_id}
                onClick={() => {
                  setActiveOrganizationId(org.organization_id);
                  setOrgAnchorEl(null);
                }}
                disabled={org.organization_id === activeOrgId}
                selected={org.organization_id === activeOrgId}
              >
                {org.organizations.name}
                <Box sx={{ flexGrow: 1 }}></Box>
                {org.organization_id === activeOrgId && <Check />}
              </MenuItem>
            ))}
            {/* <Divider />
            <MenuItem onClick={createNewOrganization}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText>Create New Organization</ListItemText>
            </MenuItem> */}
          </Menu>
        </ListItem>
        {userType === "company" && (
          <ListItemButton onClick={() => updateUserType("customer")}>
            <ListItemIcon>
              <Wysiwyg />
            </ListItemIcon>
            <ListItemText>Customer Console</ListItemText>
          </ListItemButton>
        )}
        {userType === "customer" && (
          <ListItemButton onClick={() => updateUserType("company")}>
            <ListItemIcon>
              <Wysiwyg />
            </ListItemIcon>
            <ListItemText>Company Console</ListItemText>
          </ListItemButton>
        )}
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </List>
    </Menu>
  );
};
