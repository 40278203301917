export const PRODUCT_NAME = "Congruent";

export const API_HOSTNAME =
  window.location.hostname === "localhost" ||
  window.location.hostname === "localhost"
    ? "http://localhost:5004/api"
    : "https://app.congruenthq.com/api";

export const industries = [
  // Technology & Software
  "Cloud Computing",
  "Cybersecurity",
  "AI/Machine Learning",
  "Software Development",
  "IT Services",

  // Healthcare & Life Sciences
  "Medical Devices",
  "Pharmaceuticals",
  "Biotechnology",
  "Healthcare Services",
  "Medical Research",

  // Finance & Banking
  "Investment Banking",
  "Fintech",
  "Insurance",
  "Asset Management",
  "Corporate Finance",

  // Manufacturing
  "Automotive",
  "Aerospace Manufacturing",
  "Electronics Manufacturing",
  "Heavy Machinery",
  "Robotics",

  // Energy & Utilities
  "Renewable Energy",
  "Oil & Gas",
  "Power Generation",
  "Utilities Management",
  "Environmental Services",

  // Telecommunications
  "Mobile Networks",
  "Internet Services",
  "Networking Equipment",
  "Satellite Communications",
  "Wireless Technology",

  // Retail & E-commerce
  "Online Retail",
  "Brick-and-Mortar Retail",
  "Fashion",
  "Consumer Electronics",
  "Marketplace Platforms",

  // Agriculture & Agritech
  "Precision Farming",
  "Crop Technology",
  "Agricultural Machinery",
  "Food Production",
  "Sustainable Agriculture",

  // Education & EdTech
  "Online Learning",
  "Educational Platforms",
  "Professional Training",
  "Academic Technology",
  "Corporate Learning",

  // Transportation & Logistics
  "Shipping",
  "Supply Chain Management",
  "Freight Services",
  "Logistics Technology",
  "Public Transportation",

  // Media & Entertainment
  "Streaming Services",
  "Gaming",
  "Digital Media",
  "Film & Television",
  "Content Creation",

  // Hospitality & Tourism
  "Hotel Management",
  "Travel Technology",
  "Event Management",
  "Restaurant Services",
  "Tourism Platforms",

  // Construction & Real Estate
  "Property Development",
  "Smart Buildings",
  "Construction Technology",
  "Architecture",
  "Facilities Management",

  // Aerospace & Defense
  "Military Technology",
  "Space Exploration",
  "Defense Systems",
  "Satellite Technology",
  "Aerospace Engineering",

  // Professional Services
  "Consulting",
  "Legal Services",
  "Marketing",
  "Human Resources",
  "Business Strategy",

  // Sustainability & CleanTech
  "Green Technology",
  "Waste Management",
  "Sustainable Solutions",
  "Carbon Capture",
  "Circular Economy Technologies",

  // Blockchain & Cryptocurrency
  "Decentralized Finance",
  "Crypto Exchanges",
  "Blockchain Platforms",
  "Token Economics",
  "Smart Contract Development",

  // Miscellaneous
  "Other",
];

export const companySizes = [
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1000 employees",
  "1000+ employees",
];

export const menuItemsSoftware = [
  {
    id: 2,
    name: "Sales Tools",
    url: "https://www.g2.com/categories/sales-tools",
    children: [
      {
        id: 179,
        name: "CRM",
        url: "https://www.g2.com/categories/crm",
      },
      {
        id: 42,
        name: "Sales Compensation",
        url: "https://www.g2.com/categories/sales-compensation",
      },
      {
        id: 4,
        name: "Contract Management",
        url: "https://www.g2.com/categories/contract-management",
      },
      {
        id: 152,
        name: "E-Signature",
        url: "https://www.g2.com/categories/e-signature",
      },
      {
        id: 607,
        name: "Sales Acceleration",
        url: "https://www.g2.com/categories/sales-acceleration",
      },
      {
        id: 46,
        name: "Sales Intelligence",
        url: "https://www.g2.com/categories/sales-intelligence",
      },
    ],
  },
  {
    id: 5,
    name: "Marketing",
    url: "https://www.g2.com/categories/marketing",
    children: [
      {
        id: 490,
        name: "Account-Based Marketing",
        url: "https://www.g2.com/categories/account-based-marketing",
      },
      {
        id: 242,
        name: "Content Marketing",
        url: "https://www.g2.com/categories/content-marketing",
      },
      {
        id: 272,
        name: "Conversion Rate Optimization Tools",
        url: "https://www.g2.com/categories/conversion-rate-optimization-tools",
      },
      {
        id: 1202,
        name: "Customer Data Platform (CDP)",
        url: "https://www.g2.com/categories/customer-data-platform-cdp",
      },
      {
        id: 48,
        name: "Demand Generation",
        url: "https://www.g2.com/categories/demand-generation",
      },
      {
        id: 11,
        name: "Digital Analytics",
        url: "https://www.g2.com/categories/digital-analytics",
      },
      {
        id: 39,
        name: "Email Marketing",
        url: "https://www.g2.com/categories/email-marketing",
      },
      {
        id: 53,
        name: "Event Management",
        url: "https://www.g2.com/categories/event-management",
      },
      {
        id: 624,
        name: "Marketing Analytics",
        url: "https://www.g2.com/categories/marketing-analytics",
      },
      {
        id: 36,
        name: "Marketing Automation",
        url: "https://www.g2.com/categories/marketing-automation",
      },
      {
        id: 1674,
        name: "Personalization",
        url: "https://www.g2.com/categories/personalization",
      },
      {
        id: 182,
        name: "Public Relations (PR)",
        url: "https://www.g2.com/categories/public-relations-pr",
      },
      {
        id: 250,
        name: "SEO Tools",
        url: "https://www.g2.com/categories/seo-tools",
      },
      {
        id: 1781,
        name: "SMS Marketing",
        url: "https://www.g2.com/categories/sms-marketing",
      },
      {
        id: 60,
        name: "Social Media Marketing",
        url: "https://www.g2.com/categories/social-media-marketing",
      },
      {
        id: 180,
        name: "Technology Research Services",
        url: "https://www.g2.com/categories/technology-research-services",
      },
      {
        id: 225,
        name: "Transactional Email",
        url: "https://www.g2.com/categories/transactional-email",
      },
      {
        id: 1258,
        name: "Local Listing Management",
        url: "https://www.g2.com/categories/local-listing-management",
      },
    ],
  },
  {
    id: 273,
    name: "Analytics Tools \u0026 Software",
    url: "https://www.g2.com/categories/analytics-tools-software",
    children: [
      {
        id: 70,
        name: "Business Intelligence",
        url: "https://www.g2.com/categories/business-intelligence",
      },
      {
        id: 1491,
        name: "Data Virtualization",
        url: "https://www.g2.com/categories/data-virtualization",
      },
      {
        id: 1347,
        name: "Drone Analytics",
        url: "https://www.g2.com/categories/drone-analytics",
      },
      {
        id: 100,
        name: "Enterprise Search Software",
        url: "https://www.g2.com/categories/enterprise-search-software",
      },
      {
        id: 1494,
        name: "Insight Engines",
        url: "https://www.g2.com/categories/insight-engines",
      },
      {
        id: 499,
        name: "Predictive Analytics",
        url: "https://www.g2.com/categories/predictive-analytics",
      },
      {
        id: 1789,
        name: "Statistical Analysis",
        url: "https://www.g2.com/categories/statistical-analysis",
      },
      {
        id: 1260,
        name: "Text Analysis",
        url: "https://www.g2.com/categories/text-analysis",
      },
      {
        id: 1982,
        name: "Time Series Intelligence",
        url: "https://www.g2.com/categories/time-series-intelligence",
      },
      {
        id: 1898,
        name: "Visitor Behavior Intelligence",
        url: "https://www.g2.com/categories/visitor-behavior-intelligence",
      },
    ],
  },
  {
    id: 183,
    name: "CAD \u0026 PLM",
    url: "https://www.g2.com/categories/cad-plm",
    children: [
      {
        id: 362,
        name: "GIS",
        url: "https://www.g2.com/categories/gis",
      },
      {
        id: 184,
        name: "PLM",
        url: "https://www.g2.com/categories/plm",
      },
      {
        id: 292,
        name: "Building Design and Building Information Modeling (BIM)",
        url: "https://www.g2.com/categories/building-design-and-building-information-modeling-bim",
      },
      {
        id: 294,
        name: "Civil Engineering Design",
        url: "https://www.g2.com/categories/civil-engineering-design",
      },
      {
        id: 291,
        name: "General-Purpose CAD",
        url: "https://www.g2.com/categories/general-purpose-cad",
      },
      {
        id: 293,
        name: "Mechanical Computer-aided Design (MCAD)",
        url: "https://www.g2.com/categories/mechanical-computer-aided-design-mcad",
      },
      {
        id: 1959,
        name: "PCB Design",
        url: "https://www.g2.com/categories/pcb-design",
      },
      {
        id: 295,
        name: "Sketching",
        url: "https://www.g2.com/categories/sketching",
      },
    ],
  },
  {
    id: 13,
    name: "Collaboration \u0026 Productivity",
    url: "https://www.g2.com/categories/collaboration-productivity",
    children: [
      {
        id: 15,
        name: "Team Collaboration",
        url: "https://www.g2.com/categories/team-collaboration",
      },
      {
        id: 239,
        name: "Idea Management",
        url: "https://www.g2.com/categories/idea-management",
      },
      {
        id: 871,
        name: "Meeting Management",
        url: "https://www.g2.com/categories/meeting-management",
      },
      {
        id: 1771,
        name: "UCaaS Platforms",
        url: "https://www.g2.com/categories/ucaas-platforms",
      },
      {
        id: 19,
        name: "Video Conferencing",
        url: "https://www.g2.com/categories/video-conferencing",
      },
      {
        id: 118,
        name: "VoIP",
        url: "https://www.g2.com/categories/voip",
      },
    ],
  },
  {
    id: 2045,
    name: "Commerce",
    url: "https://www.g2.com/categories/commerce",
    children: [
      {
        id: 92,
        name: "POS",
        url: "https://www.g2.com/categories/pos",
      },
      {
        id: 34,
        name: "E-Commerce Platforms",
        url: "https://www.g2.com/categories/e-commerce-platforms",
      },
      {
        id: 566,
        name: "E-Merchandising",
        url: "https://www.g2.com/categories/e-merchandising",
      },
      {
        id: 95,
        name: "Payment Gateways",
        url: "https://www.g2.com/categories/payment-gateways",
      },
      {
        id: 96,
        name: "Review Management",
        url: "https://www.g2.com/categories/review-management",
      },
      {
        id: 81,
        name: "Subscription Management",
        url: "https://www.g2.com/categories/subscription-management",
      },
      {
        id: 1836,
        name: "Brand Protection",
        url: "https://www.g2.com/categories/brand-protection",
      },
      {
        id: 1891,
        name: "Drop Shipping",
        url: "https://www.g2.com/categories/drop-shipping",
      },
      {
        id: 1528,
        name: "E-Commerce Personalization",
        url: "https://www.g2.com/categories/e-commerce-personalization",
      },
      {
        id: 412,
        name: "Product Information Management (PIM)",
        url: "https://www.g2.com/categories/product-information-management-pim",
      },
      {
        id: 1964,
        name: "Retail Execution",
        url: "https://www.g2.com/categories/retail-execution",
      },
      {
        id: 263,
        name: "Shopping Cart",
        url: "https://www.g2.com/categories/shopping-cart",
      },
    ],
  },
  {
    id: 167,
    name: "Content Management",
    url: "https://www.g2.com/categories/content-management",
    children: [
      {
        id: 260,
        name: "Digital Asset Management",
        url: "https://www.g2.com/categories/digital-asset-management",
      },
      {
        id: 1414,
        name: "Digital Experience Platforms (DXP)",
        url: "https://www.g2.com/categories/digital-experience-platforms-dxp",
      },
      {
        id: 199,
        name: "Enterprise Content Management (ECM)",
        url: "https://www.g2.com/categories/enterprise-content-management-ecm",
      },
      {
        id: 1205,
        name: "Headless CMS",
        url: "https://www.g2.com/categories/headless-cms",
      },
      {
        id: 1043,
        name: "Knowledge Management",
        url: "https://www.g2.com/categories/knowledge-management",
      },
      {
        id: 129,
        name: "Localization",
        url: "https://www.g2.com/categories/localization",
      },
      {
        id: 1044,
        name: "Mobile Forms Automation",
        url: "https://www.g2.com/categories/mobile-forms-automation",
      },
      {
        id: 561,
        name: "Translation Management",
        url: "https://www.g2.com/categories/translation-management",
      },
      {
        id: 2247,
        name: "Web Accessibility",
        url: "https://www.g2.com/categories/web-accessibility",
      },
      {
        id: 51,
        name: "Web Content Management",
        url: "https://www.g2.com/categories/web-content-management",
      },
    ],
  },
  {
    id: 8,
    name: "Customer Service",
    url: "https://www.g2.com/categories/customer-service",
    children: [
      {
        id: 58,
        name: "Help Desk",
        url: "https://www.g2.com/categories/help-desk",
      },
      {
        id: 191,
        name: "Live Chat",
        url: "https://www.g2.com/categories/live-chat",
      },
      {
        id: 10,
        name: "Customer Self-Service",
        url: "https://www.g2.com/categories/customer-self-service",
      },
      {
        id: 479,
        name: "Social Customer Service",
        url: "https://www.g2.com/categories/social-customer-service",
      },
      {
        id: 202,
        name: "Call \u0026 Contact Center",
        url: "https://www.g2.com/categories/call-contact-center",
      },
      {
        id: 1896,
        name: "Conversational Support",
        url: "https://www.g2.com/categories/conversational-support",
      },
      {
        id: 351,
        name: "Customer Success",
        url: "https://www.g2.com/categories/customer-success",
      },
      {
        id: 32,
        name: "Enterprise Feedback Management",
        url: "https://www.g2.com/categories/enterprise-feedback-management",
      },
      {
        id: 2378,
        name: "Experience Management",
        url: "https://www.g2.com/categories/experience-management",
      },
    ],
  },
  {
    id: 2117,
    name: "Data Privacy",
    url: "https://www.g2.com/categories/data-privacy-3d79da1e-6384-42b3-a11f-d04b6694e806",
    children: [
      {
        id: 2189,
        name: "Consent Management Platform (CMP)",
        url: "https://www.g2.com/categories/consent-management-platform-cmp",
      },
      {
        id: 2127,
        name: "Data Privacy Management",
        url: "https://www.g2.com/categories/data-privacy-management",
      },
      {
        id: 2171,
        name: "Data Subject Access Request (DSAR)",
        url: "https://www.g2.com/categories/data-subject-access-request-dsar",
      },
      {
        id: 2172,
        name: "Privacy Impact Assessment (PIA)",
        url: "https://www.g2.com/categories/privacy-impact-assessment-pia",
      },
      {
        id: 2262,
        name: "Sensitive Data Discovery",
        url: "https://www.g2.com/categories/sensitive-data-discovery",
      },
    ],
  },
  {
    id: 88,
    name: "Design",
    url: "https://www.g2.com/categories/design",
    children: [
      {
        id: 203,
        name: "Video",
        url: "https://www.g2.com/categories/video",
      },
    ],
  },
  {
    id: 166,
    name: "Development",
    url: "https://www.g2.com/categories/development",
    children: [
      {
        id: 59,
        name: "Cloud Platform as a Service (PaaS)",
        url: "https://www.g2.com/categories/cloud-platform-as-a-service-paas",
      },
      {
        id: 309,
        name: "Integrated Development Environments (IDE)",
        url: "https://www.g2.com/categories/integrated-development-environments-ide",
      },
      {
        id: 314,
        name: "Software Testing",
        url: "https://www.g2.com/categories/software-testing",
      },
      {
        id: 611,
        name: "Application Development",
        url: "https://www.g2.com/categories/application-development",
      },
      {
        id: 217,
        name: "Game Development",
        url: "https://www.g2.com/categories/game-development",
      },
      {
        id: 1993,
        name: "Software Development Analytics Tools",
        url: "https://www.g2.com/categories/software-development-analytics-tools",
      },
      {
        id: 1947,
        name: "Test Management",
        url: "https://www.g2.com/categories/test-management",
      },
      {
        id: 545,
        name: "Web Frameworks",
        url: "https://www.g2.com/categories/web-frameworks",
      },
      {
        id: 1996,
        name: "WebOps Platforms",
        url: "https://www.g2.com/categories/webops-platforms",
      },
      {
        id: 315,
        name: "Bug Tracking",
        url: "https://www.g2.com/categories/bug-tracking",
      },
      {
        id: 316,
        name: "Text Editor",
        url: "https://www.g2.com/categories/text-editor",
      },
      {
        id: 1461,
        name: "Application Release Orchestration",
        url: "https://www.g2.com/categories/application-release-orchestration",
      },
      {
        id: 108,
        name: "Automation Testing",
        url: "https://www.g2.com/categories/automation-testing",
      },
      {
        id: 1691,
        name: "Batch Management",
        url: "https://www.g2.com/categories/batch-management",
      },
      {
        id: 1976,
        name: "CI/CD Tools",
        url: "https://www.g2.com/categories/ci-cd-tools",
      },
      {
        id: 1130,
        name: "Containerization",
        url: "https://www.g2.com/categories/containerization",
      },
      {
        id: 562,
        name: "Source Code Management",
        url: "https://www.g2.com/categories/source-code-management",
      },
    ],
  },
  {
    id: 243,
    name: "Digital Advertising Tech",
    url: "https://www.g2.com/categories/digital-advertising-tech",
    children: [
      {
        id: 1705,
        name: "Click Fraud",
        url: "https://www.g2.com/categories/click-fraud",
      },
      {
        id: 289,
        name: "Cross-Channel Advertising",
        url: "https://www.g2.com/categories/cross-channel-advertising",
      },
      {
        id: 287,
        name: "Data Management Platform (DMP)",
        url: "https://www.g2.com/categories/data-management-platform-dmp",
      },
      {
        id: 281,
        name: "Demand Side Platform (DSP)",
        url: "https://www.g2.com/categories/demand-side-platform-dsp",
      },
      {
        id: 277,
        name: "Display Advertising",
        url: "https://www.g2.com/categories/display-advertising",
      },
      {
        id: 278,
        name: "Mobile Advertising",
        url: "https://www.g2.com/categories/mobile-advertising",
      },
      {
        id: 288,
        name: "Other Digital Advertising",
        url: "https://www.g2.com/categories/other-digital-advertising",
      },
      {
        id: 282,
        name: "Publisher Ad Management",
        url: "https://www.g2.com/categories/publisher-ad-management",
      },
      {
        id: 1714,
        name: "Retargeting",
        url: "https://www.g2.com/categories/retargeting",
      },
      {
        id: 249,
        name: "Search Advertising",
        url: "https://www.g2.com/categories/search-advertising",
      },
      {
        id: 279,
        name: "Social Media Advertising",
        url: "https://www.g2.com/categories/social-media-advertising",
      },
      {
        id: 286,
        name: "Supply Side Platform (SSP)",
        url: "https://www.g2.com/categories/supply-side-platform-ssp",
      },
      {
        id: 280,
        name: "Video Advertising",
        url: "https://www.g2.com/categories/video-advertising",
      },
    ],
  },
  {
    id: 45,
    name: "ERP",
    url: "https://www.g2.com/categories/erp",
    children: [
      {
        id: 137,
        name: "Procurement",
        url: "https://www.g2.com/categories/procurement",
      },
      {
        id: 1553,
        name: "Discrete ERP",
        url: "https://www.g2.com/categories/discrete-erp",
      },
      {
        id: 1554,
        name: "Distribution ERP",
        url: "https://www.g2.com/categories/distribution-erp",
      },
      {
        id: 2238,
        name: "Environmental, Quality and Safety Management",
        url: "https://www.g2.com/categories/environmental-quality-and-safety-management",
      },
      {
        id: 634,
        name: "ERP Systems",
        url: "https://www.g2.com/categories/erp-systems",
      },
      {
        id: 2315,
        name: "Industrial IoT",
        url: "https://www.g2.com/categories/industrial-iot",
      },
      {
        id: 1300,
        name: "Manufacturing Execution System",
        url: "https://www.g2.com/categories/manufacturing-execution-system",
      },
      {
        id: 1555,
        name: "Mixed Mode ERP",
        url: "https://www.g2.com/categories/mixed-mode-erp",
      },
      {
        id: 156,
        name: "Professional Services Automation",
        url: "https://www.g2.com/categories/professional-services-automation",
      },
      {
        id: 109,
        name: "Project, Portfolio \u0026 Program Management",
        url: "https://www.g2.com/categories/project-portfolio-program-management",
      },
      {
        id: 65,
        name: "Accounting",
        url: "https://www.g2.com/categories/accounting",
      },
      {
        id: 1361,
        name: "Accounts Receivable",
        url: "https://www.g2.com/categories/accounts-receivable",
      },
      {
        id: 1360,
        name: "AP Automation",
        url: "https://www.g2.com/categories/ap-automation",
      },
      {
        id: 90,
        name: "Billing",
        url: "https://www.g2.com/categories/billing",
      },
      {
        id: 431,
        name: "CMMS",
        url: "https://www.g2.com/categories/cmms",
      },
      {
        id: 215,
        name: "Corporate Performance Management (CPM)",
        url: "https://www.g2.com/categories/corporate-performance-management-cpm",
      },
      {
        id: 663,
        name: "Enterprise Asset Management (EAM)",
        url: "https://www.g2.com/categories/enterprise-asset-management-eam",
      },
      {
        id: 1108,
        name: "Financial Close",
        url: "https://www.g2.com/categories/financial-close",
      },
      {
        id: 1200,
        name: "Invoice Management",
        url: "https://www.g2.com/categories/invoice-management",
      },
      {
        id: 43,
        name: "Travel \u0026 Expense",
        url: "https://www.g2.com/categories/travel-expense",
      },
    ],
  },
  {
    id: 107,
    name: "Governance, Risk \u0026 Compliance",
    url: "https://www.g2.com/categories/governance-risk-compliance",
    children: [
      {
        id: 1437,
        name: "Anti Money Laundering",
        url: "https://www.g2.com/categories/anti-money-laundering",
      },
      {
        id: 1438,
        name: "Audit Management",
        url: "https://www.g2.com/categories/audit-management",
      },
      {
        id: 1447,
        name: "Enterprise Risk Management (ERM)",
        url: "https://www.g2.com/categories/enterprise-risk-management-erm",
      },
      {
        id: 1444,
        name: "Ethics and Compliance Learning",
        url: "https://www.g2.com/categories/ethics-and-compliance-learning",
      },
      {
        id: 1445,
        name: "Policy Management",
        url: "https://www.g2.com/categories/policy-management",
      },
      {
        id: 1441,
        name: "Third Party \u0026 Supplier Risk Management",
        url: "https://www.g2.com/categories/third-party-supplier-risk-management",
      },
    ],
  },
  {
    id: 140,
    name: "Hosting",
    url: "https://www.g2.com/categories/hosting",
    children: [
      {
        id: 142,
        name: "Content Delivery Network (CDN)",
        url: "https://www.g2.com/categories/content-delivery-network-cdn",
      },
      {
        id: 143,
        name: "Domain Registration",
        url: "https://www.g2.com/categories/domain-registration",
      },
      {
        id: 1531,
        name: "Enterprise Content Delivery Network (eCDN)",
        url: "https://www.g2.com/categories/enterprise-content-delivery-network-ecdn",
      },
      {
        id: 1355,
        name: "Managed DNS Providers",
        url: "https://www.g2.com/categories/managed-dns-providers",
      },
      {
        id: 55,
        name: "Web Hosting",
        url: "https://www.g2.com/categories/web-hosting",
      },
    ],
  },
  {
    id: 21,
    name: "HR",
    url: "https://www.g2.com/categories/hr",
    children: [
      {
        id: 47,
        name: "Human Resource Management Systems",
        url: "https://www.g2.com/categories/human-resource-management-systems",
      },
      {
        id: 241,
        name: "Core HR",
        url: "https://www.g2.com/categories/core-hr",
      },
      {
        id: 590,
        name: "Benefits Administration",
        url: "https://www.g2.com/categories/benefits-administration",
      },
      {
        id: 171,
        name: "Workforce Management",
        url: "https://www.g2.com/categories/workforce-management",
      },
      {
        id: 247,
        name: "Time Tracking",
        url: "https://www.g2.com/categories/time-tracking",
      },
      {
        id: 252,
        name: "Staffing",
        url: "https://www.g2.com/categories/staffing",
      },
      {
        id: 63,
        name: "Employee Monitoring",
        url: "https://www.g2.com/categories/employee-monitoring",
      },
      {
        id: 261,
        name: "Video Interviewing",
        url: "https://www.g2.com/categories/video-interviewing",
      },
      {
        id: 50,
        name: "Performance Management",
        url: "https://www.g2.com/categories/performance-management",
      },
      {
        id: 591,
        name: "Employee Engagement",
        url: "https://www.g2.com/categories/employee-engagement",
      },
      {
        id: 589,
        name: "Compensation Management",
        url: "https://www.g2.com/categories/compensation-management",
      },
      {
        id: 1201,
        name: "Employee Recognition",
        url: "https://www.g2.com/categories/employee-recognition",
      },
      {
        id: 698,
        name: "Job Search Sites",
        url: "https://www.g2.com/categories/job-search-sites",
      },
      {
        id: 565,
        name: "Corporate Learning Management Systems",
        url: "https://www.g2.com/categories/corporate-learning-management-systems",
      },
      {
        id: 790,
        name: "Recruitment Marketing",
        url: "https://www.g2.com/categories/recruitment-marketing",
      },
      {
        id: 789,
        name: "Applicant Tracking Systems (ATS)",
        url: "https://www.g2.com/categories/applicant-tracking-systems-ats",
      },
      {
        id: 324,
        name: "Course Authoring",
        url: "https://www.g2.com/categories/course-authoring",
      },
      {
        id: 1318,
        name: "Learning Experience Platforms (LXP)",
        url: "https://www.g2.com/categories/learning-experience-platforms-lxp",
      },
    ],
  },
  {
    id: 121,
    name: "IT Infrastructure",
    url: "https://www.g2.com/categories/it-infrastructure",
    children: [
      {
        id: 80,
        name: "Infrastructure as a Service (IaaS)",
        url: "https://www.g2.com/categories/infrastructure-as-a-service-iaas",
      },
      {
        id: 2107,
        name: "AIOps Platforms",
        url: "https://www.g2.com/categories/aiops-platforms",
      },
      {
        id: 1127,
        name: "Blockchain",
        url: "https://www.g2.com/categories/blockchain",
      },
      {
        id: 103,
        name: "Database Software",
        url: "https://www.g2.com/categories/database-software",
      },
      {
        id: 78,
        name: "Data Integration Tools",
        url: "https://www.g2.com/categories/data-integration-tools",
      },
      {
        id: 77,
        name: "Data Warehouse",
        url: "https://www.g2.com/categories/data-warehouse",
      },
      {
        id: 1221,
        name: "Load Balancing",
        url: "https://www.g2.com/categories/load-balancing",
      },
      {
        id: 411,
        name: "Master Data Management (MDM)",
        url: "https://www.g2.com/categories/master-data-management-mdm",
      },
      {
        id: 2109,
        name: "Monitoring",
        url: "https://www.g2.com/categories/monitoring",
      },
      {
        id: 200,
        name: "Storage Management",
        url: "https://www.g2.com/categories/storage-management",
      },
      {
        id: 189,
        name: "Virtual Desktop Infrastructure (VDI)",
        url: "https://www.g2.com/categories/virtual-desktop-infrastructure-vdi",
      },
      {
        id: 1401,
        name: "Virtual Private Cloud (VPC)",
        url: "https://www.g2.com/categories/virtual-private-cloud-vpc",
      },
    ],
  },
  {
    id: 72,
    name: "IT Management",
    url: "https://www.g2.com/categories/it-management",
    children: [
      {
        id: 2036,
        name: "Cloud Management Platforms",
        url: "https://www.g2.com/categories/cloud-management-platforms",
      },
      {
        id: 1124,
        name: "Data Recovery",
        url: "https://www.g2.com/categories/data-recovery",
      },
      {
        id: 648,
        name: "Enterprise IT Management",
        url: "https://www.g2.com/categories/enterprise-it-management",
      },
      {
        id: 1073,
        name: "Enterprise Mobility Management",
        url: "https://www.g2.com/categories/enterprise-mobility-management",
      },
      {
        id: 1040,
        name: "Incident Management",
        url: "https://www.g2.com/categories/incident-management",
      },
      {
        id: 1391,
        name: "IT Asset Management",
        url: "https://www.g2.com/categories/it-asset-management",
      },
      {
        id: 161,
        name: "Mobile Device Management (MDM)",
        url: "https://www.g2.com/categories/mobile-device-management-mdm",
      },
      {
        id: 1255,
        name: "Process Automation",
        url: "https://www.g2.com/categories/process-automation",
      },
      {
        id: 82,
        name: "Service Desk",
        url: "https://www.g2.com/categories/service-desk",
      },
      {
        id: 1375,
        name: "Workload Automation",
        url: "https://www.g2.com/categories/workload-automation",
      },
    ],
  },
  {
    id: 1402,
    name: "Marketplace Apps",
    url: "https://www.g2.com/categories/marketplace-apps",
    children: [
      {
        id: 2485,
        name: "Marketo LaunchPoint Apps",
        url: "https://www.g2.com/categories/marketo-launchpoint-apps",
      },
    ],
  },
  {
    id: 608,
    name: "Office",
    url: "https://www.g2.com/categories/office",
    children: [
      {
        id: 237,
        name: "Document Creation",
        url: "https://www.g2.com/categories/document-creation",
      },
      {
        id: 1265,
        name: "Meeting Room Booking Systems",
        url: "https://www.g2.com/categories/meeting-room-booking-systems",
      },
      {
        id: 345,
        name: "Presentation",
        url: "https://www.g2.com/categories/presentation",
      },
      {
        id: 28,
        name: "Survey",
        url: "https://www.g2.com/categories/survey",
      },
    ],
  },
  {
    id: 201,
    name: "Security",
    url: "https://www.g2.com/categories/security",
    children: [
      {
        id: 1084,
        name: "Cloud Security",
        url: "https://www.g2.com/categories/cloud-security",
      },
      {
        id: 1106,
        name: "System Security",
        url: "https://www.g2.com/categories/system-security",
      },
      {
        id: 1089,
        name: "Application Security",
        url: "https://www.g2.com/categories/application-security",
      },
      {
        id: 1104,
        name: "Confidentiality",
        url: "https://www.g2.com/categories/confidentiality",
      },
      {
        id: 169,
        name: "Data Security",
        url: "https://www.g2.com/categories/data-security",
      },
      {
        id: 1607,
        name: "Email Security",
        url: "https://www.g2.com/categories/email-security",
      },
      {
        id: 154,
        name: "Endpoint Protection",
        url: "https://www.g2.com/categories/endpoint-protection",
      },
      {
        id: 153,
        name: "Identity Management",
        url: "https://www.g2.com/categories/identity-management",
      },
      {
        id: 641,
        name: "Network Security",
        url: "https://www.g2.com/categories/network-security",
      },
      {
        id: 211,
        name: "Vulnerability Management",
        url: "https://www.g2.com/categories/vulnerability-management",
      },
      {
        id: 640,
        name: "Web Security",
        url: "https://www.g2.com/categories/web-security",
      },
      {
        id: 1136,
        name: "Log Analysis",
        url: "https://www.g2.com/categories/log-analysis",
      },
      {
        id: 2041,
        name: "Software Composition Analysis",
        url: "https://www.g2.com/categories/software-composition-analysis",
      },
    ],
  },
  {
    id: 130,
    name: "Supply Chain \u0026 Logistics",
    url: "https://www.g2.com/categories/supply-chain-logistics",
    children: [
      {
        id: 134,
        name: "Demand Planning",
        url: "https://www.g2.com/categories/demand-planning",
      },
      {
        id: 374,
        name: "Shipping",
        url: "https://www.g2.com/categories/shipping",
      },
      {
        id: 373,
        name: "Fleet Management",
        url: "https://www.g2.com/categories/fleet-management",
      },
      {
        id: 372,
        name: "Transportation Management Systems (TMS)",
        url: "https://www.g2.com/categories/transportation-management-systems-tms",
      },
    ],
  },
  {
    id: 186,
    name: "Vertical Industry",
    url: "https://www.g2.com/categories/vertical-industry",
    children: [
      {
        id: 383,
        name: "Construction",
        url: "https://www.g2.com/categories/construction",
      },
      {
        id: 219,
        name: "Education",
        url: "https://www.g2.com/categories/education",
      },
      {
        id: 209,
        name: "Financial Services",
        url: "https://www.g2.com/categories/financial-services",
      },
      {
        id: 204,
        name: "Health Care",
        url: "https://www.g2.com/categories/health-care",
      },
      {
        id: 190,
        name: "Legal",
        url: "https://www.g2.com/categories/legal",
      },
      {
        id: 151,
        name: "Nonprofit",
        url: "https://www.g2.com/categories/nonprofit",
      },
      {
        id: 677,
        name: "Transportation",
        url: "https://www.g2.com/categories/transportation",
      },
      {
        id: 1004793,
        name: "Other Religious Institutions",
        url: "https://www.g2.com/categories/other-religious-institutions",
      },
    ],
  },
];

const menuItemsService = [
  {
    id: 794,
    name: "Business Services",
    url: "https://www.g2.com/categories/business-services",
    children: [
      {
        id: 795,
        name: "Business Filing and Licensing",
        url: "https://www.g2.com/categories/business-filing-and-licensing",
      },
      {
        id: 796,
        name: "Business Finance",
        url: "https://www.g2.com/categories/business-finance",
      },
      {
        id: 802,
        name: "HR Services",
        url: "https://www.g2.com/categories/hr-services",
      },
      {
        id: 809,
        name: "Legal Services",
        url: "https://www.g2.com/categories/legal-services",
      },
      {
        id: 174,
        name: "Management Consulting",
        url: "https://www.g2.com/categories/management-consulting",
      },
      {
        id: 1074,
        name: "Sales Consulting",
        url: "https://www.g2.com/categories/sales-consulting",
      },
      {
        id: 1895,
        name: "Sales Training",
        url: "https://www.g2.com/categories/sales-training",
      },
    ],
  },
  {
    id: 1003048,
    name: "Ecosystem Service Providers",
    url: "https://www.g2.com/categories/ecosystem-service-providers",
    children: [
      {
        id: 1004496,
        name: "Adobe Consulting Services",
        url: "https://www.g2.com/categories/adobe-consulting-services",
      },
      {
        id: 1005591,
        name: "AWS Consulting Services",
        url: "https://www.g2.com/categories/aws-consulting-services",
      },
      {
        id: 1007409,
        name: "Braze Consulting Services",
        url: "https://www.g2.com/categories/braze-consulting-services",
      },
      {
        id: 1007310,
        name: "Ceridian Consulting Services",
        url: "https://www.g2.com/categories/ceridian-consulting-services",
      },
      {
        id: 1007541,
        name: "Citrix Consulting Services",
        url: "https://www.g2.com/categories/citrix-consulting-services",
      },
      {
        id: 1007045,
        name: "Genesys Consulting Services",
        url: "https://www.g2.com/categories/genesys-consulting-services",
      },
      {
        id: 1004133,
        name: "Google Consulting Services",
        url: "https://www.g2.com/categories/google-consulting-services",
      },
      {
        id: 1003572,
        name: "HubSpot Consulting Services",
        url: "https://www.g2.com/categories/hubspot-consulting-services",
      },
      {
        id: 1005757,
        name: "IBM Consulting Services",
        url: "https://www.g2.com/categories/ibm-consulting-services",
      },
      {
        id: 1006781,
        name: "Infor Consulting Services",
        url: "https://www.g2.com/categories/infor-consulting-services",
      },
      {
        id: 1003242,
        name: "Microsoft Consulting Services",
        url: "https://www.g2.com/categories/microsoft-consulting-services",
      },
      {
        id: 1007012,
        name: "NetSuite Consulting Services",
        url: "https://www.g2.com/categories/netsuite-consulting-services",
      },
      {
        id: 1003638,
        name: "Oracle Consulting Services",
        url: "https://www.g2.com/categories/oracle-consulting-services",
      },
      {
        id: 1003047,
        name: "Salesforce Consulting Services",
        url: "https://www.g2.com/categories/salesforce-consulting-services",
      },
      {
        id: 1003275,
        name: "SAP Consulting Services",
        url: "https://www.g2.com/categories/sap-consulting-services",
      },
      {
        id: 1005988,
        name: "ServiceNow Consulting Services",
        url: "https://www.g2.com/categories/servicenow-consulting-services",
      },
      {
        id: 1006351,
        name: "UKG Consulting Services",
        url: "https://www.g2.com/categories/ukg-consulting-services",
      },
      {
        id: 1004595,
        name: "Workday Consulting Services",
        url: "https://www.g2.com/categories/workday-consulting-services",
      },
    ],
  },
  {
    id: 771,
    name: "Marketing Services",
    url: "https://www.g2.com/categories/marketing-services",
    children: [
      {
        id: 785,
        name: "Branding Agencies",
        url: "https://www.g2.com/categories/branding-agencies",
      },
      {
        id: 1722,
        name: "Event Management Services",
        url: "https://www.g2.com/categories/event-management-services",
      },
      {
        id: 779,
        name: "Inbound Marketing",
        url: "https://www.g2.com/categories/inbound-marketing",
      },
      {
        id: 1377,
        name: "Lead Generation Services",
        url: "https://www.g2.com/categories/lead-generation-services",
      },
      {
        id: 1076,
        name: "Marketing Automation Consulting",
        url: "https://www.g2.com/categories/marketing-automation-consulting",
      },
      {
        id: 787,
        name: "Marketing Strategy",
        url: "https://www.g2.com/categories/marketing-strategy",
      },
      {
        id: 772,
        name: "Outbound Marketing",
        url: "https://www.g2.com/categories/outbound-marketing",
      },
      {
        id: 786,
        name: "PR Firms",
        url: "https://www.g2.com/categories/pr-firms",
      },
      {
        id: 1721,
        name: "Reputation Management Services",
        url: "https://www.g2.com/categories/reputation-management-services",
      },
    ],
  },
  {
    id: 145,
    name: "Other Services",
    url: "https://www.g2.com/categories/other-services-a3556bb7-df48-4d51-af00-82ad0505f4c5",
    children: [
      {
        id: 146,
        name: "IT Outsourcing",
        url: "https://www.g2.com/categories/it-outsourcing",
      },
      {
        id: 1512,
        name: "ITSM Tool Implementation, Consulting, and Managed Services",
        url: "https://www.g2.com/categories/itsm-tool-implementation-consulting-and-managed-services",
      },
      {
        id: 649,
        name: "Other B2B Services",
        url: "https://www.g2.com/categories/other-b2b-services",
      },
    ],
  },
  {
    id: 12,
    name: "Professional Services",
    url: "https://www.g2.com/categories/professional-services-bd3033f2-37d6-456f-88bb-632ef5fc83f5",
    children: [
      {
        id: 1723,
        name: "Cloud Migration Services",
        url: "https://www.g2.com/categories/cloud-migration-services",
      },
      {
        id: 1050,
        name: "Development Services",
        url: "https://www.g2.com/categories/development-services",
      },
      {
        id: 749,
        name: "Implementation Services",
        url: "https://www.g2.com/categories/implementation-services",
      },
      {
        id: 1749,
        name: "Managed IT Services",
        url: "https://www.g2.com/categories/managed-it-services",
      },
      {
        id: 1199,
        name: "Managed Services",
        url: "https://www.g2.com/categories/managed-services",
      },
      {
        id: 762,
        name: "Solution Consulting",
        url: "https://www.g2.com/categories/solution-consulting",
      },
    ],
  },
  {
    id: 1091,
    name: "Security and Privacy Services",
    url: "https://www.g2.com/categories/security-and-privacy-services",
    children: [
      {
        id: 1092,
        name: "Application Security Services",
        url: "https://www.g2.com/categories/application-security-services",
      },
      {
        id: 1093,
        name: "Data Security Services",
        url: "https://www.g2.com/categories/data-security-services",
      },
      {
        id: 1094,
        name: "Email Security Services",
        url: "https://www.g2.com/categories/email-security-services",
      },
      {
        id: 1095,
        name: "Endpoint Security Services",
        url: "https://www.g2.com/categories/endpoint-security-services",
      },
      {
        id: 1096,
        name: "Incident Response Services",
        url: "https://www.g2.com/categories/incident-response-services",
      },
      {
        id: 2115,
        name: "IoT Security Services",
        url: "https://www.g2.com/categories/iot-security-services",
      },
      {
        id: 1097,
        name: "IT Compliance Services",
        url: "https://www.g2.com/categories/it-compliance-services",
      },
      {
        id: 1099,
        name: "Network Security Services",
        url: "https://www.g2.com/categories/network-security-services",
      },
      {
        id: 2120,
        name: "Threat Hunting Services",
        url: "https://www.g2.com/categories/threat-hunting-services",
      },
      {
        id: 1100,
        name: "Threat Intelligence Services",
        url: "https://www.g2.com/categories/threat-intelligence-services",
      },
      {
        id: 1101,
        name: "Vulnerability Assessment Services",
        url: "https://www.g2.com/categories/vulnerability-assessment-services",
      },
    ],
  },
  {
    id: 697,
    name: "Staffing Services",
    url: "https://www.g2.com/categories/staffing-services",
    children: [
      {
        id: 699,
        name: "On-Demand Staffing Services",
        url: "https://www.g2.com/categories/on-demand-staffing-services",
      },
      {
        id: 700,
        name: "Other Staffing Services",
        url: "https://www.g2.com/categories/other-staffing-services",
      },
      {
        id: 701,
        name: "Recruitment Agencies",
        url: "https://www.g2.com/categories/recruitment-agencies",
      },
      {
        id: 164,
        name: "Staffing Agencies",
        url: "https://www.g2.com/categories/staffing-agencies",
      },
    ],
  },
  {
    id: 732,
    name: "Translation Services",
    url: "https://www.g2.com/categories/translation-services",
    children: [
      {
        id: 1196,
        name: "Closed Captioning Services",
        url: "https://www.g2.com/categories/closed-captioning-services",
      },
      {
        id: 733,
        name: "Interpretation",
        url: "https://www.g2.com/categories/interpretation",
      },
      {
        id: 736,
        name: "Localization Services",
        url: "https://www.g2.com/categories/localization-services",
      },
      {
        id: 734,
        name: "Multilingual Desktop Publishing",
        url: "https://www.g2.com/categories/multilingual-desktop-publishing",
      },
      {
        id: 735,
        name: "Transcription Services",
        url: "https://www.g2.com/categories/transcription-services",
      },
      {
        id: 567,
        name: "Translation",
        url: "https://www.g2.com/categories/translation",
      },
    ],
  },
  {
    id: 939,
    name: "Value-Added Resellers (VARs)",
    url: "https://www.g2.com/categories/value-added-resellers-vars",
    children: [
      {
        id: 949,
        name: "Acumatica Channel Partners",
        url: "https://www.g2.com/categories/acumatica-channel-partners",
      },
      {
        id: 948,
        name: "Adobe Channel Partners",
        url: "https://www.g2.com/categories/adobe-channel-partners",
      },
      {
        id: 978,
        name: "Autodesk Channel Partners",
        url: "https://www.g2.com/categories/autodesk-channel-partners",
      },
      {
        id: 940,
        name: "Cisco Channel Partners",
        url: "https://www.g2.com/categories/cisco-channel-partners",
      },
      {
        id: 963,
        name: "Deltek Channel Partners",
        url: "https://www.g2.com/categories/deltek-channel-partners",
      },
      {
        id: 975,
        name: "Epicor Channel Partners",
        url: "https://www.g2.com/categories/epicor-channel-partners",
      },
      {
        id: 991,
        name: "Infor Channel Partners",
        url: "https://www.g2.com/categories/infor-channel-partners",
      },
      {
        id: 950,
        name: "Microsoft Channel Partners",
        url: "https://www.g2.com/categories/microsoft-channel-partners",
      },
      {
        id: 1005,
        name: "Oracle Channel Partners",
        url: "https://www.g2.com/categories/oracle-channel-partners",
      },
      {
        id: 1022,
        name: "Other VARs",
        url: "https://www.g2.com/categories/other-vars",
      },
      {
        id: 976,
        name: "Qlik Channel Partners",
        url: "https://www.g2.com/categories/qlik-channel-partners",
      },
      {
        id: 964,
        name: "Sage Channel Partners",
        url: "https://www.g2.com/categories/sage-channel-partners",
      },
      {
        id: 947,
        name: "Salesforce Channel Partners",
        url: "https://www.g2.com/categories/salesforce-channel-partners",
      },
      {
        id: 979,
        name: "SAP Channel Partners",
        url: "https://www.g2.com/categories/sap-channel-partners",
      },
    ],
  },
];
